import { graphql } from 'gatsby';
import { shape, string } from 'prop-types';
import React from 'react';

import BlockImages from '../blocks/block-images';
import Layout from '../components/layout';
import Link from '../components/link';
import Markdown from '../components/markdown';
import SEO from '../components/SEO';
import useJobCategory from '../hooks/useJobCategory';
import SectionOpenerJobDetails from '../sections/section-opener/section-opener-job-detail';
import { resolve } from '../urls';

const JobPage = ({ location, data }) => {
  const { id, createdat, department, description, friendly_id, office, name, employmenttype } =
    data.job;
  const applyUrl = resolve('jobs:apply', { jobSlug: friendly_id, id });
  const category = useJobCategory(data.job.category);
  const mappedDescription = description
    .map((desc) => `<p><strong>${desc.name}</strong></p>` + desc.value)
    .join('');

  return (
    <Layout className="page--job-detail" pathname={location.pathname}>
      <SEO
        title={name}
        description="Make things that matter. Join Edenspiekermann"
        structuredData={[
          {
            '@context': 'https://schema.org',
            '@type': 'JobPosting',
            'title': name,
            'description': description[0].value,
            'employmentType': employmenttype || 'Not specified',
            'employmentUnit': department,
            'datePosted': createdat,
            'hiringOrganization': {
              '@type': 'Organization',
              'name': 'Edenspiekermann',
            },
            'jobLocation': {
              '@type': 'Place',
              'address': {
                '@type': 'PostalAddress',
                'addressLocality': office,
              },
            },
          },
        ]}
      />
      <div className="job-detail-layout">
        <SectionOpenerJobDetails
          title={name}
          location={office}
          contract={employmenttype}
          category={category}
          applyUrl={applyUrl}
        />

        <Markdown markdown={mappedDescription} />
      </div>

      <div className="job-detail-moodimages">
        <BlockImages {...category.mood_images} specialLayout="overlap-top" />
      </div>

      <div className="job-detail-claim">
        <p className="job-detail-claim__text">{category.claim}</p>
        <Link className="job-detail-claim__button button" to={applyUrl}>
          Apply now
        </Link>
      </div>
    </Layout>
  );
};

export default JobPage;

JobPage.propTypes = {
  data: shape({
    job: shape({
      id: string.isRequired,
      friendly_id: string.isRequired,
      name: string.isRequired,
      department: string,
      description: Array.isRequired,
      category: string.isRequired,
      office: string.isRequired,
      employmenttype: string.isRequired,
      createdat: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query ($id: String!) {
    job: jobsJson(id: { eq: $id }) {
      id: id
      friendly_id: id
      name
      department
      description: jobdescriptions {
        name
        value
      }
      category: occupationcategory
      office
      employmenttype
      createdat
    }
  }
`;
