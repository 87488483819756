import classNames from 'classnames';
import React from 'react';

import Image from '../components/image';
import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const SIZE_MODIFIER_DEFAULT = 'narrow';
const SIZE_MODIFIER_IMAGE_MAP = {
  'narrow': 'images',
  'medium': 'images_medium',
  'wide': 'images_wide',
  'full': 'images_full',
  'edge-to-edge': 'images_edge_to_edge',
};

class BlockImages extends React.Component {
  get size() {
    const { size_modifier } = this.props;
    const size = size_modifier || SIZE_MODIFIER_DEFAULT;
    return size;
  }

  get images() {
    const key =
      SIZE_MODIFIER_IMAGE_MAP[this.size] || SIZE_MODIFIER_IMAGE_MAP[SIZE_MODIFIER_DEFAULT];
    const images = this.props[key] || [];
    return images;
  }

  render = () => {
    const { backgroundColor, specialLayout } = this.props;
    if (!backgroundColor) return this.renderImages();

    const imgWrapperClasses = classNames({
      'image-block__wrapper': true,
      [`image-block__wrapper--${specialLayout}`]: !!specialLayout,
    });

    return (
      <div className={imgWrapperClasses} style={{ backgroundColor }}>
        {this.renderImages()}
      </div>
    );
  };

  renderImages() {
    const { tight, specialLayout, captionColor } = this.props;
    const { images, size } = this;

    const imgBlockClasses = classNames({
      'block-content': true,
      'image-block': true,
      [`image-block--${size}`]: true,
      'image-block--tight': tight,
      'image-block--4-up': images.length === 4,
      [`image-block--${specialLayout}`]: !!specialLayout,
    });

    return (
      <div className={imgBlockClasses}>
        <GroupAnimation className="image-block__container">
          {images &&
            images.map((image, index) => (
              <GroupedBlockAnimation
                as="figure"
                className="image-block__figure"
                key={index}
                delay={DELAY_OFFSET * index}
              >
                <Image className="image-block__figure-image" image={image} alt={image.title} />
                {image.description && (
                  <figcaption
                    className="image-block__figure-caption"
                    style={{ color: captionColor }}
                  >
                    {image.description}
                  </figcaption>
                )}
              </GroupedBlockAnimation>
            ))}
        </GroupAnimation>
      </div>
    );
  }
}

export default BlockImages;
