import classnames from 'classnames';
import noop from 'lodash/noop';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Image from '../../components/image';
import Link from '../../components/link';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import useJobCategory from '../../hooks/useJobCategory';

import SectionOpener from './section-opener';

const Details = ({
  title,
  location,
  contract,
  applyUrl,
  onChangeVisibilityTitle,
  onChangeVisibilityInfo,
  onChangeVisibilityElement,
}) => {
  const { ref: titleRef, inView: titleInView } = useIntersectionObserver({
    threshold: 1,
  });
  const { ref: infoRef, inView: infoInView } = useIntersectionObserver({
    threshold: 1,
  });
  const { ref: elementRef, inView: elementInView } = useIntersectionObserver({
    threshold: 0,
  });

  useEffect(() => {
    onChangeVisibilityTitle(titleInView);
  }, [titleInView, onChangeVisibilityTitle]);

  useEffect(() => {
    onChangeVisibilityInfo(infoInView);
  }, [infoInView, onChangeVisibilityInfo]);

  useEffect(() => {
    onChangeVisibilityElement(elementInView);
  }, [elementInView, onChangeVisibilityElement]);

  return (
    <>
      <div className="job-detail-opener__details" ref={elementRef}>
        <h1 className="job-detail-opener__title" ref={titleRef}>
          {title}
        </h1>
        <dl className="job-detail-opener__info" ref={infoRef}>
          <div className="job-detail-opener__info__section">
            <dt className="job-detail-opener__info__title job-detail-opener__info__title--gender">
              Gender
            </dt>
            <dd className="job-detail-opener__info__data">
              <abbr className="job-detail-opener__info__abbr" title="Female, Male, or Divers">
                (f/m/d)
              </abbr>
            </dd>
          </div>
          {location && (
            <div className="job-detail-opener__info__section">
              <dt className="job-detail-opener__info__title job-detail-opener__info__title--location">
                Location
              </dt>
              <dd className="job-detail-opener__info__data">{location}</dd>
            </div>
          )}
          {contract && (
            <div className="job-detail-opener__info__section">
              <dt className="job-detail-opener__info__title job-detail-opener__info__title--contract">
                Contract
              </dt>
              <dd className="job-detail-opener__info__data">{contract}</dd>
            </div>
          )}
        </dl>
      </div>

      {applyUrl && (
        <Link to={applyUrl} className="button job-detail-opener__button">
          Apply now
        </Link>
      )}
    </>
  );
};

Details.defaultProps = {
  onChangeVisibilityTitle: noop,
  onChangeVisibilityInfo: noop,
  onChangeVisibilityElement: noop,
};

const SectionOpenerJobDetail = ({ hasBackground, ...props }) => {
  const category = useJobCategory(props.category);

  const [isScrollPastTitle, setScrollPastTitle] = useState(false);
  const [isScrollPastInfo, setScrollPastInfo] = useState(false);
  const [isScrollPastElement, setScrollPastElement] = useState(false);

  const onChangeVisibilityTitle = useCallback(
    (isVisible) => setScrollPastTitle(!isVisible),
    [setScrollPastTitle]
  );
  const onChangeVisibilityInfo = useCallback(
    (isVisible) => setScrollPastInfo(!isVisible),
    [setScrollPastInfo]
  );
  const onChangeVisibilityElement = useCallback(
    (isVisible) => setScrollPastElement(!isVisible),
    [setScrollPastElement]
  );

  return (
    <div
      className={classnames({
        'job-detail-opener': true,
        'job-detail-opener--is-past-title': isScrollPastTitle,
        'job-detail-opener--is-past-info': isScrollPastInfo,
        'job-detail-opener--is-past-element': isScrollPastElement,
      })}
    >
      {/* The following element is required to simulte a sticky behaviour on
       * mobile. We need to keep a static positioned element that reserves the
       * place of the content while the "visual" details are using position=fixed
       * to achieve the effect */}
      <div role="presentation" aria-hidden="true">
        <SectionOpener openerType="job-detail section-opener--placeholder">
          <div className="job-detail-opener__container">
            <Details
              {...{
                onChangeVisibilityTitle,
                onChangeVisibilityInfo,
                onChangeVisibilityElement,
                ...props,
              }}
            />
          </div>
        </SectionOpener>
      </div>

      <SectionOpener openerType="job-detail section-opener--visible">
        <div className="job-detail-opener__container">
          <Details {...props} />
        </div>
        {hasBackground && (
          <div className="job-detail-opener__image">
            <Image
              className="job-detail-opener__image__background"
              image={category.main_image}
              asBackground
            />
          </div>
        )}
      </SectionOpener>
    </div>
  );
};

SectionOpenerJobDetail.defaultProps = {
  hasBackground: true,
};

export default SectionOpenerJobDetail;
