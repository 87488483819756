import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

const QUERY = graphql`
  query {
    categories: allContentfulJobCategory {
      edges {
        node {
          name
          default
          claim
          main_image {
            title
            description
            file {
              contentType
            }
            gatsbyImageData(layout: CONSTRAINED, width: 840, quality: 75)
          }
          mood_images {
            ...BlockImages
          }
        }
      }
    }
  }
`;

const useJobCategory = (name) => {
  const { categories } = useStaticQuery(QUERY);

  const category = useMemo(() => {
    let item = categories.edges.find(({ node }) => node.name === name);
    item = item || categories.edges.find(({ node }) => node.default === true);

    if (!item) {
      throw new Error(
        'Did not found a job category. Please mark one job category in contentful as default.'
      );
    }

    return item.node;
  }, [categories, name]);

  return category;
};

export default useJobCategory;
